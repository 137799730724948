<script>
import api from '@/command/api'
import { message } from 'ant-design-vue'
import DetailFormGroup from '../../../components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import Modal from './modal'

export default {
  name: 'freightDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        detailList: [],
        name: '',
      },
      type2List: [],
      type3List: [],
    }
  },
  computed: {
    isEdit() {
      return this.$route.query.id
    },
  },
  mounted() {
    const { id } = this.$route.query

    if (id) {
      this.getDataInit2(id)
    } else {
      api.freight.addTemplate
        .call(this, {
          url: '/config/farmFreight/add',
        })
        .then(() => {
          this.handleDetailList(this.detail.detailList)
          message.success('添加模板')
        })
    }
  },
  methods: {
    handleDetailList(detailList, name = '') {
      this.type2List = detailList.filter((item) => item.type !== '3')
      this.type3List = detailList.filter((item) => item.type === '3')
      this.detail.name = name
    },
    handleDetailList2(detailList) {
      this.type2List = detailList.filter((item) => item.type !== '3')
      this.type3List = detailList.filter((item) => item.type === '3')
    },
    getDataInit(id = this.detail.id) {
      let name = this.detail.name ?? ''
      api.command.getDetail
        .call(this, {
          url: `/config/farmFreight/getDetail?id=${id}`,
        })
        .then((result) => {
          if (result) {
            this.handleDetailList(this.detail.detailList ?? [], name)
          } else {
            this.$message.error('数据出错,请联系开发人员！')
          }
          //   this.$forceUpdate()
        })
    },
    getDataInit2(id = this.detail.id) {
      api.command.getDetail
        .call(this, {
          url: `/config/farmFreight/getDetail?id=${id}`,
        })
        .then((result) => {
          this.handleDetailList2(this.detail.detailList)
          //   this.$forceUpdate()
        })
    },

    getForm1() {
      return {
        title: '模板信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            key: 'name',
            type: 'input',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    handleUpdateData(data) {
      apiTool.showDrawer({
        title: 'Create',
        width: '720px',
        view: Modal,
        viewProps: {
          freightDetailId: data.id,
          freightId: this.detail.id,
          freightType: data.type,
        },
        success: ({ setHidden }) => {
          setHidden()
          this.getDataInit()
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getForm2() {
      return {
        type: 'table',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        on: {
          handleAddData: () => {
            this.type2List.push({
              type: '2',
            })
          },
          delListData: (id, index) => {
            // if (this.type2List[index].type === '1') {
            //   return message.warning('全国数据无法删除')
            // }

            if (id) {
              api.freight.deleteDetail
                .call(this, {
                  url: `/config/farmFreightDetail/delete?id=${id}`,
                })
                .then(() => {
                  this.getDataInit()
                })
            } else {
              this.type2List.splice(index, 1)
            }
          },
        },
        columns: [
          {
            dataIndex: 'areaName',
            align: 'left',
            title: '运送到',
            width: '48%',
            customRender: (text, record) => {
              return record.type === '1' ? '中国' : text
            },
          },
          {
            dataIndex: 'firstCount',
            align: 'center',
            title: '首件（个）',
            width: 100,
          },
          {
            dataIndex: 'firstPrice',
            align: 'center',
            title: '运费（元）',
            width: 100,
          },
          {
            dataIndex: 'otherCount',
            align: 'center',
            title: '增加件（个）',
            width: 100,
          },
          {
            dataIndex: 'otherPrice',
            align: 'center',
            title: '增加运费（元）',
            width: 120,
          },
          {
            dataIndex: 'a3',
            align: 'center',
            width: '12%',
            title: '操作',
            tableType: 'editDelete',
            onEdit: (text, records) => {
              this.handleUpdateData(records)
            },
          },
        ],
        dataSource: this.type2List,
      }
    },
    getForm3() {
      return {
        type: 'table',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        on: {
          handleAddData: () => {
            this.type3List.push({
              type: '3',
            })
          },
          delListData: (id, index) => {
            if (id) {
              api.freight.deleteDetail
                .call(this, {
                  url: `/config/farmFreightDetail/delete?id=${id}`,
                })
                .then(() => {
                  this.getDataInit()
                })
            } else {
              this.type3List.splice(index, 1)
            }
          },
        },
        columns: [
          {
            dataIndex: 'areaName',
            align: 'left',
            title: '运送到',
            width: '48%',
          },
          {
            dataIndex: 'limitAmount',
            align: 'center',
            title: '',
            width: '35%',
            customRender: (text, record) => {
              let str = ''

              record.childType === '1' ? (str = `${record.limitAmount || 0}元`) : (str = `${record.limitCount || 0}件`)

              return `满${str}包邮`
            },
          },
          {
            dataIndex: 'a3',
            align: 'center',
            width: '200px',
            title: '操作',
            tableType: 'editDelete',
            onEdit: (text, records) => {
              this.handleUpdateData(records)
            },
          },
        ],
        dataSource: this.type3List,
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: () => {
            api.command.editPost
              .call(this, {
                url: '/config/farmFreight/update',
                params: {
                  ...this.detail,
                },
                isPost: false,
              })
              .then(() => {
                this.$router.back()
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          submit: true,
          onClick: () => this.$router.back(),
        },
      ]
      const right = this.isEdit && [
        {
          name: '删除',
          type: 'danger',
          isPop: true,
          popTitle: '确认删除吗?',
          onClick: () => {
            api.command.delPost.call(this, {
              url: '/config/farmFreight/delete',
              params: {
                id: this.detail.id,
              },
              isPost: false,
            })
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      return {
        left,
        right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
        isCustom={true}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
